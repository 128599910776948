<template>
    <div class="character_details">
        <Top  name="商品还价" email back></Top>
        <div class="dicker_w">
            <van-loading v-if="isLoading" color="#1989fa" />
            <div v-else class="dicker_wrap">
                <div class="index_list">
                    <img class="profilePhoto" :src="detailData.imgSrc">
                    <span class="index_list_c">
                        <div class="index_list_c_t">{{detailData.channel_name}}\{{detailData.job}}\{{detailData.lv}}级</div>
                        <div class="index_list_c_c">{{detailData.server_name}}</div>
                        <div class="index_list_c_b">￥{{detailData.price}}</div>
                    </span>
                    <span class="index_list_r">
                        已有<span>{{dickerCount}}</span>人还价
                    </span>
                </div>
                <div class="dicker_price">
                    <van-field v-model="price" type="digit" label="金额（元）" placeholder="请输入您的还价价格" class="inp" />
                    <div class="price_d">
                        <span class="orange">还价金额不可低于售价{{pricePer}}%</span>
                        <span class="gray">对商品还剩<span class="orange">{{priceCount}}</span>次还价机会</span>
                    </div>
                </div>
                <div class="message_btn">
                    <div>
                        <van-button round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)" @click="priceClick">确认还价</van-button>
                    </div>
                </div>
                <div class="message_tips">
                    <p class="gray">提示：</p>
                    <p class="gray">当卖家同意或拒绝还价时将给您发送站内信消息 <br /> 当您的还价被接受后请在24小时内支付，以免被其它卖家抢走</p>
                </div>
            </div>
            <div class="pop_wrap">
                <van-popup v-model="priceShow" position="top">
                    <div class="pop_w">
                        <p>还价成功，您总共还剩{{surplusTime}}次还价机会，您希望卖家给您短信反馈吗？</p>
                        <!-- <p class="red">( 注：交易行内物品不予展示，请自行取回至背包 )</p> -->
                    </div>
                    <div class="pop_btns">
                        <van-button round type="info" color="linear-gradient(to right, #FF704D, #F73727)" size="small" @click="confirmClick">需要</van-button>
                        <van-button plain round type="default" size="small" @click="priceShow=false;">拒绝</van-button>
                    </div>
                </van-popup>
            </div>
        </div>
    </div>
</template>
<script>
import Top from '@/components/top'

export default {
    name:"Dicker",
    data(){
        return {
            isLoading: true,
            params: {},
            detailData: {
                channel_name: '仙境传奇',
                job: '战士',
                lv: '134',
                server_name: '时光倒流',
                price: '2000',
                imgSrc: require('../../assets/images/photos/zs-nan@2x.png'),
                goods_id: '',
                nickname: '',
                seedname: '',
                status_name: '',
                surplus_time: '',
            },
            price: '',
            dickerCount: 13,
            pricePer: 80,
            priceCount: 3,
            priceShow: false,
            surplusTime: 9
        }
    },
    components: {
      Top
    },
    created() {
        this.params = this.$route.query;
        //console.log(this.params)
        //获取详情数据
        this.getDetailData();
        //判断是否已安全设置
        //this.isSettig();
    },
    computed:{
        btnText:function(){
            if(this.detailData.status==8){
                // console.log('公示期')
                return this.detailData.surplus_time
            }else if(this.params.is_from=='sold'){
               return '已售出'
            }else{
                return '立即购买'
            }
        }
    },
    methods:{
        //下拉刷新
        onRefresh() {
            //获取详情数据
            this.getDetailData();
        },
        //获取商品详情数据
        getDetailData() {
            this.$api.shopping.goodsDetails({
                goods_id: this.params.goods_id
            }).then(res => {
                this.refreshing = false;
                this.isLoading = false
                if (res.code == 200) {
                    this.detailData = res.data.goods_detal;
                    //剩余时间处理
                    this.detailData.surplus_time = this.detailData.surplus_time.indexOf('剩余') > -1 ? this.detailData.surplus_time.substring(3) : this.detailData.surplus_time;
                    //获取头像
                    if (this.detailData.sex == 200) {
                        switch(this.detailData.job) {
                            case '战士':
                                this.detailData.imgSrc = require('../../assets/images/photos/zs-nan@2x.png');
                                break;
                            case '法师':
                                this.detailData.imgSrc = require('../../assets/images/photos/fa-nan@2x.png');
                                break;
                            default:
                                this.detailData.imgSrc = require('../../assets/images/photos/ds-nan@2x.png');
                        }
                    }else{
                        switch(this.detailData.job) {
                            case '战士':
                                this.detailData.imgSrc = require('../../assets/images/photos/zs-nv@2x.png');
                                break;
                            case '法师':
                                this.detailData.imgSrc = require('../../assets/images/photos/fa-nv@2x.png');
                                break;
                            default:
                                this.detailData.imgSrc = require('../../assets/images/photos/ds-nv@2x.png');
                        }
                    }
                    //获取详情列表名称
                    this.cellList = res.data.goods_menu;
                    this.loading = false;
                }
            }).catch(err => {
                this.isLoading = false
                //console.log(err);
            });
        },
        //确认还价弹窗
        priceClick() {
            this.priceShow = true;
        },
        //确认还价
        confirmClick() {
            this.priceShow = false;
        }

    }
    
}
</script>
<style scoped>
.character_details{
    background:#fff;
    height: 100%;
}
.character_details .index_list{
    display: flex;
    padding: 1.1875rem 1rem;
    border-bottom: 1px solid #bbb;
    background: #fff;
    align-items: end;
    border-top: 1px solid #bbb;
    margin-top: .3125rem;
}
.character_details .index_list .index_list_c{
    width: 56%;
    text-align: left;
    padding-left: .40625rem;
}
.character_details .index_list .index_list_c .index_list_c_t{
    font: .875rem/1.125rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #313131;
    padding-bottom: .21875rem;
    margin-bottom: 0;
}
.character_details .index_list .index_list_c .index_list_c_c{
    font: .75rem/1.15625rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #707070;
    padding-bottom: .21875rem;
}
.character_details .index_list .index_list_c .index_list_c_b{
    font: .875rem/1.0625rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #ff4e00;
}
.character_details .index_list .profilePhoto{
    width: 3.75rem;
    height: 3.75rem;
    margin-right: 3px;
}
.character_details .index_list .index_list_r{
    color: #707070;
}
.character_details .index_list .index_list_r span{
    color: #ff4e00;
}
.dicker_price .inp{
    border-bottom: 1px solid #bbb;
    position: relative;
}
.dicker_price .inp::before{
    content: '';
    position: absolute;
    left: 7em;
    top: 50%;
    height: 76%;
    width: 1px;
    background-color: #7e7e7e;
    transform: translate(0, -50%);
}
.dicker_price .price_d{
    font: .5625rem/1.0625rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
}

.message_btn>div{
   padding: .9375rem 1.4375rem; 
}
.blue{
    color: #007eff;
}
.gray{
    color: #707070;
}
.orange{
    color: #ff4e00;
}
.message_tips{
    padding: .9375rem 1.4375rem;
    text-align: left;
}
</style>